<template>
  <div id="mail">
    <!-- <div class="fullFoot mailBox mobileWidth">
      <pre class="mail">制作のご依頼 / 문의</pre>
      <div class="mMb"></div>
      <img src="../assets/image/webmaster.png" alt class="iMb" />
    </div> -->
    <div class="fullFoot mailBox mobileWidth">
      <div>
        <!-- <pre class="mail">制作・出演のご依頼 / 문의 / business proposal, bookings</pre> -->
        <pre class="mail">お問合せ / business proposal, bookings</pre>
        <div class="mMb"></div>
        <a href="https://webform.jp/aiobahn/info/" target="_blank" rel="noopener noreferrer">
          <img src="../assets/image/avex.gif" alt class="iMb" style="margin: 1rem 0 0 0;" />
        </a>

      </div>
    </div>
    <div v-if="announcement" class="fullFoot mailBox mobileWidth">
      <pre class="mail">{{ announcement }}</pre>
    </div>
    <!-- <div class="fullFoot mailBox mobileWidth">
      <pre class="mail">licensing, copyright inquiries</pre>
      <div class="mMb"></div>
      <img src="../assets/image/copyright.png" alt class="iMb" />
    </div> -->
  </div>
</template>

<script>
export default {
  name: "Mail",
  data: () => ({
    announcement: "",
  }),
  mounted() {
    this.getAnnouncement();
  },
  methods: {
    async getAnnouncement() {
      const r = await this.$firebase
        .firestore()
        .collection("Look")
        .doc("look")
        .get();
      this.announcement = r.data().announcement;
    },
  },
};
</script>

<style>
#mail {
  /* display: flex; */
  width: 100%;
  height: fit-content;
  /* justify-content: center;
  flex-wrap: wrap; */
}

.mailBox {
  margin-bottom: 15px;
}

.mailDesktop {
  display: flex;
}

.mMb {
  display: none;
}

.iMb {
  margin-left: 15px;
}

@media only screen and (max-width: 768px) {
  .mailDesktop {
    display: none;
  }

  #mail {
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: center;
    flex-wrap: wrap;
  }

  .mMb {
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: center;
    flex-wrap: wrap;
  }

  .iMb {
    margin: 5px 0 15px 0;
  }

  .mailBox {
    margin-bottom: 0;
  }

  .mail {
    text-wrap: wrap;
    white-space: pre-line;
  }

  .iMb {
    margin-left: 0;
  }
}
</style>
